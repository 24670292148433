<template>
  <div class="main-content">
    <b-modal
      id="confirm-remove-user"
      hide-backdrop
      content-class="shadow"
      title="Verwijderen"
    >
      <template v-slot:modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          variant="success"
          @click.prevent="removeUser"
        >
          Ja
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          size="sm"
          variant="outline-secondary"
          @click.prevent="cancel()"
        >
          Nee
        </b-button>
      </template>
      <p class="my-4">
        Weet u zeker dat u deze gebruiker wilt verwijderen?
      </p>
    </b-modal>
    <div class="card user-profile o-hidden mb-30">
      <div class="header-cover" />
      <button
        v-if="user._id"
        type="button"
        class="btn btn-float btn-danger"
        @click.prevent="showConfirmRemoveUser"
      >
        <b-icon icon="trash" />
      </button>
      <div class="clearfix" />
      <div class="user-info">
        <img
          class="profile-picture avatar-lg mb-2"
          src="@/assets/images/default.png"
          alt=""
        >
        <p class="m-0 text-24">
          {{ user.displayName }}
        </p>
      </div>
      <b-card class="wrapper">
        <b-tabs
          content-class="mt-3"
          align="center"
        >
          <b-tab
            title="Personalia"
            active
          >
            <user-item
              :item="user"
              :on-save="saveUser"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import { usersService } from '@/services';
import { Roles } from '@/helpers';
import UserItem from '@/components/user-item.vue';

export default {
  components: {
    UserItem,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Deelnemers',
  },
  data() {
    return {
      user: {
        roles: ['admin'],
      },
      okLabel: 'Opslaan',
      cancelLabel: 'Sluiten',
    };
  },
  computed: {},
  created() {
    if (this.$route.params.id === '_new') {
      this.user = {
        roles: [],
      };
      this.user.roles.push(Roles.Admin);
      this.user.isNew = true;
    } else {
      usersService.getById(this.$route.params.id).then((user) => {
        this.user = user;
      });
    }
  },
  methods: {
    saveUser() {
      usersService
        .save(this.user)
        .then((res) => {
          if (this.user.isNew) {
            this.$router.push(`/users/${res._id}`);
          }
          this.user = res;
          this.$bvToast.toast('Opgeslagen', {
            title: 'Melding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'info',
          });
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: 'Foutmelding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'danger',
          });
        });
    },
    showConfirmRemoveUser() {
      this.$bvModal.show('confirm-remove-user');
    },
    removeUser() {
      usersService
        .remove(this.user)
        .then((res) => {
          this.$bvModal.hide('confirm-remove-user');
          this.$bvToast.toast('Verwijderd', {
            title: 'Melding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'info',
          });
          this.$router.push('/users');
        })
        .catch((err) => {
          console.error(err);
          this.$bvToast.toast(err, {
            title: 'Foutmelding',
            autoHideDelay: 2500,
            appendToast: false,
            variant: 'danger',
          });
        });
    },
  },
};
</script>
<style></style>
